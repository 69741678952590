import { FC, HTMLAttributes, useEffect, useState } from 'react'

// Hooks
import { OxomiOptions, useOxomiData } from '@obeta/data/lib/hooks/useOxomiData'

// Assets
import { ReactComponent as ProductPlaceholder } from '@obeta/assets/img/CardProductPlaceholder.svg' // Direct import without ReactComponent doesn't display svg file

export interface IProductOxomiImage extends HTMLAttributes<HTMLImageElement> {
  alt: string
  componentType?: 'card' | 'item' // Card type displays bigger placeholder
  src: string
  oxomiId: string
  supplierId: string
  supplierImage?: string
}

interface IAvailableImages {
  src: string
  state: 'visible' | 'hidden' | 'error'
  type: 'component' | 'image'
}

export const ProductOxomiImage: FC<IProductOxomiImage> = (props) => {
  const brokenimage =
    'https://s3.eu-central-1.amazonaws.com/headlesscms/Card_Product_Placeholder_5e2f7a2ed4.svg'
  let oxomi: OxomiOptions | null = null
  const toFetch = {
    images: true,
    video: false,
    attachments: false,
    pages: false,
  }
  if (props.oxomiId && props.supplierId) {
    oxomi = {
      id: props.oxomiId,
      supplierId: props.supplierId,
    }
  }
  const { oxomiImages } = useOxomiData(oxomi, toFetch)
  const [availableImages, setAvailableImages] = useState<IAvailableImages[]>([
    // Product image
    { src: props.src ?? '', state: props.src ? 'visible' : 'hidden', type: 'image' },
    // Oxomi image
    { src: '', state: 'hidden', type: 'image' },
    // Supplier image
    {
      src: props.supplierImage ?? '',
      state: props.supplierImage ? 'visible' : 'hidden',
      type: 'image',
    },
    // Error image / product placeholder when there is no supplier image available
    {
      src: props.componentType === 'card' ? 'placeholder' : brokenimage,
      state: 'visible',
      type: props.componentType === 'card' ? 'component' : 'image',
    },
  ])
  const [activeIndex, setActiveIndex] = useState(0)

  const handleError = () => {
    availableImages[activeIndex].state = 'error'
    setAvailableImages(([] as IAvailableImages[]).concat(availableImages))
  }

  useEffect(() => {
    if (props.src && availableImages[0].src !== props.src) {
      availableImages[0].src = props.src
      availableImages[0].state = 'visible'
      setAvailableImages(([] as IAvailableImages[]).concat(availableImages))
    }
    // Add oxomi images to the object when it was loaded
    if (oxomiImages[0]?.large && availableImages[1].src !== oxomiImages[0]?.large) {
      availableImages[1].src = oxomiImages[0]?.large
      availableImages[1].state = 'visible'
      setAvailableImages(([] as IAvailableImages[]).concat(availableImages))
    }
    if (props.supplierImage && availableImages[2].src !== props.supplierImage) {
      availableImages[2].src = props.supplierImage
      availableImages[2].state = 'visible'
      setAvailableImages(([] as IAvailableImages[]).concat(availableImages))
    }
  }, [props.componentType, props.src, oxomiImages, props.supplierImage, availableImages])

  useEffect(() => {
    //Show the first visible images in the object
    for (let index = 0; index < availableImages.length; index++) {
      if (availableImages[index].state === 'visible') {
        setActiveIndex(index)
        break
      }
    }
  }, [availableImages])

  if (availableImages[activeIndex].type === 'image') {
    return (
      <img
        onClick={props.onClick}
        src={availableImages[activeIndex].src}
        alt={props.alt}
        className={props.className}
        onError={handleError}
        onLoad={props.onLoad}
      />
    )
  } else {
    return <ProductPlaceholder className={props.className} /> // Using svg as image src is not working
  }
}
